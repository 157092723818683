<template lang="html">
	<div id="container">
		<div class="">
			<div class="">
				<vx-card title="Datos requeridos" class="">
          <div class="text-center" v-if="!state_data">
            <span class="red">Recuerda actualizar la fecha de nacimiento e información de ingresos del solicitante</span>
          </div>
					<div class="row w-full">
						<div class="col-md-4">
							<span class="span-placeholder">Ingreso mensual*</span>
							<input class="vs-input" v-model="ingreso.ingresoMensual" name="username" @change="formateaIngresoMensual()" v-on:keyup="validaIngersoMensual()" v-on:keypress="isNumber($event)" />
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Ingresos netos*</span>
							<input class="vs-input" v-model="ingreso.ingresoNeto" name="name" @change="formateaIngresoNeto()" v-on:keyup="validaIngresoNeto()" v-on:keypress="isNumber($event)" />
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Empresa</span>
							<input class="vs-input" v-model="ingreso.empresa" name="username" />
						</div>
					</div>
					<div class="row w-full">
						<div class="col-md-4">
							<span class="span-placeholder" for="tiempoEmpleo">Tipo de empleo *</span>
							<select name="cars" id="tiempoEmpleo" v-model="ingreso.tipoEmpleo" class="vs-input">
								<option :value="''">Seleccione...</option>
								<option :value="tipo.Id" v-for="tipo in tiposDeEmpleo" :key="">{{ tipo.Nombre }}</option>
							</select>
						</div>
						<div class="col-md-4">
							<span class="span-placeholder">Fecha de inicio</span>
							<input class="vs-input" type="date" v-model="ingreso.fechaInicio" name="fechaNacimiento" />
						</div>
						<!-- <div class="col-md-4">
              <span class="span-placeholder">Fuente</span>
              <input class="vs-input" v-model="ingreso.fuente" name="username" />
            </div>-->
					</div>
					<br />
					<div class="accordion" id="AccordionIngresos">
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingOneING">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneING" aria-expanded="true" aria-controls="collapseOneING">Adicionales requeridos para llenado de solicitudes</button>
							</h2>
							<div id="collapseOneING" class="accordion-collapse collapse" aria-labelledby="headingOneING" data-bs-parent="#AccordionIngresos">
								<div class="accordion-body">
									<div class="row w-full">
										<div class="col-md-4">
											<span class="span-placeholder">Domicilio (calle)</span>
											<input class="vs-input" v-model="ingreso.domicilio" name="name" />
										</div>
										<div class="col-md-4">
											<span class="span-placeholder">Número Exterior</span>
											<input class="vs-input" v-model="ingreso.numeroExterior" name="name" />
										</div>
										<div class="col-md-4">
											<span class="span-placeholder">Número Interior</span>
											<input class="vs-input" v-model="ingreso.numeroInterior" name="name" />
										</div>
									</div>
									<div class="row w-full">
										<div class="col-md-4">
											<span class="span-placeholder">Código postal</span>
											<input class="vs-input" v-model="ingreso.codigoPostal" name="name" @blur="getColoniaMunicipio(1)"/>
										</div>
										<div class="col-md-4">
											<span class="span-placeholder">Municipio</span>
											<input class="vs-input" v-model="ingreso.municipio" name="name" disabled/>
										</div>										
									</div>
									<div class="row w-full">
										<div class="col-md-4">
											<span class="span-placeholder" v-if="LabelColoniaObligado">Colonia *</span>
                    						<span class="span-placeholder" v-if="LabelColoniaNoObligado">Colonia </span>
											<select class="vs-input" v-model="ingreso.colonia" name="name">
												<option value="" selected>Selecciona una colonia</option>
												<option :value='colonia' v-for="colonia in ColoniasCP" :key="">{{colonia}}</option>
											</select>
											<span class="text-danger span-placeholder" v-if="LabelColoniaObligadoText">Campo requerido</span>
										</div>
										<div class="col-md-4">
											<span class="span-placeholder">Ciudad</span>
											<input class="vs-input" v-model="ingreso.ciudad" name="username" />
										</div>
										<div class="col-md-4">
											<span class="span-placeholder">Teléfono (10 dígitos) *</span>
											<input class="vs-input" v-model="ingreso.telefono" name="ingresoTelefono" />
											<span class="text-danger span-placeholder" v-if="lblTelefonoRequerido">Campo requerido</span>
										</div>
									</div>
									<div class="row w-full">
										<div class="col-md-4">
											<span class="span-placeholder" for="formasEnganche">Estado</span>
											<select name="cars" id="formasEnganche" v-model="ingreso.estado" class="vs-input">
												<option :value="''">Seleccione...</option>
												<option :value="tipo.Id" v-for="tipo in estados" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
										<div class="col-md-4">
											<span class="span-placeholder">País</span>
											<select 
												name="name" 
												v-model="ingreso.pais" 
												class="vs-input"
											>
												<option :value='pais.Nombre' v-for="pais in paisesNac" :key="">{{ pais.Nombre }}</option>
											</select>
											<!-- <input class="vs-input" v-model="ingreso.pais" name="name" /> -->
										</div>
										<div class="col-md-4">
											<span class="span-placeholder">Puesto</span>
											<input class="vs-input" v-model="ingreso.puesto" name="name" />
										</div>
									</div>
									<div class="row w-full">
										<div class="col-md-4">
											<span class="span-placeholder">Área o departamento</span>
											<input class="vs-input" v-model="ingreso.area" name="area" v-validate="'max:32'" />
											<span class="text-danger span-placeholder" v-show="errors.has('area')">Máximo 32 caracteres, son {{ ingreso.area.length }}</span>
										</div>
										<div class="col-md-4">
											<span class="span-placeholder" for="formasEnganche">Tipo de Contrato</span>
											<select name="cars" id="formasEnganche" v-model="ingreso.tipoContrato" class="vs-input">
												<option :value="''">Seleccione...</option>
												<option :value="tipo.Id" v-for="tipo in tiposContratos" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
										<div class="col-md-4">
											<span class="span-placeholder" for="formasEnganche">Giro</span>
											<select name="cars" id="formasEnganche" v-model="ingreso.giro" class="vs-input">
												<option :value="''">Seleccione...</option>
												<option :value="tipo.Id" v-for="tipo in giros" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
									</div>
									<div class="row w-full">
										<div class="col-md-4">
											<span class="span-placeholder">Retiene impuestos</span>
											<vs-checkbox v-model="ingreso.impuestos"></vs-checkbox>
										</div>
										<div class="col-md-4">
											<span class="span-placeholder" for="formasEnganche">Sector laboral</span>
											<select name="cars" id="formasEnganche" v-model="ingreso.sector" class="vs-input">
												<option :value="''">Seleccione...</option>
												<option :value="tipo.Id" v-for="tipo in sectores" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
										<div class="col-md-4">
											<span class="span-placeholder">Profesión</span>
											<input class="vs-input" v-model="ingreso.profesion" name="name" />
										</div>
									</div>
									<div class="row w-full">
										<div class="col-md-4">
											<span class="span-placeholder" for="formasEnganche">Comprueba ingresos con</span>
											<select name="cars" id="formasEnganche" v-model="ingreso.comprobanteIngresos" class="vs-input">
												<option :value="''">Seleccione...</option>
												<option :value="tipo.Id" v-for="tipo in comprobantes" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
										<div class="col-md-4">
											<span class="span-placeholder">Jefe inmediato</span>
											<input class="vs-input" v-model="ingreso.jefe" name="name" />
										</div>
										<div class="col-md-4">
											<span class="span-placeholder">Descripción breve de las funciones laborales</span>
											<input class="vs-input" v-model="ingreso.funciones" name="name" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingTwoING">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoING" aria-expanded="false" aria-controls="collapseTwoING">Empleo anterior</button>
							</h2>
							<div id="collapseTwoING" class="accordion-collapse collapse" aria-labelledby="headingTwoING" data-bs-parent="#AccordionIngresos">
								<div class="accordion-body">
									<div class="row w-full">
										<div class="col-md-6">
											<span class="span-placeholder">Empresa o actividad de su empleo anterior</span>
											<input class="vs-input" v-model="anterior.empresaAnterior" name="name" />
										</div>
										<div class="col-md-6">
											<span class="span-placeholder">Causa de separación (renuncia, despido, etc)</span>
											<input class="vs-input" v-model="anterior.separacion" name="name" />
										</div>
									</div>
									<div class="row w-full">
										<div class="col-md-6">
											<span class="span-placeholder">Fecha de inicio en el empleo anterior</span>
											<input class="vs-input" v-model="anterior.inicioAnterior" name="name" type="date" />
										</div>
										<div class="col-md-6">
											<span class="span-placeholder">Fecha de salida en el empleo anterior</span>
											<input class="vs-input" v-model="anterior.salidaAnterior" name="name" type="date" />
										</div>
									</div>
									<div class="row w-full">
										<div class="col-md-6">
											<span class="span-placeholder">Teléfono</span>
											<input class="vs-input" v-model="anterior.telefono" name="name" />
										</div>
										<div class="col-md-6">
											<span class="span-placeholder">Jefe</span>
											<input class="vs-input" v-model="anterior.jefe" name="name" />
										</div>
									</div>
									<div class="row w-full">
										<div class="col-md-4">
											<span class="span-placeholder" for="formasEnganche">Tipo de Contrato</span>
											<select name="cars" id="formasEnganche" v-model="anterior.contrato" class="vs-input">
												<option :value="''">Seleccione...</option>
												<option :value="tipo.Id" v-for="tipo in tiposContratos" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
										<div class="col-md-4">
											<span class="span-placeholder" for="formasEnganche">Sector laboral</span>
											<select name="cars" id="formasEnganche" v-model="anterior.sector" class="vs-input">
												<option :value="''">Seleccione...</option>
												<option :value="tipo.Id" v-for="tipo in sectores" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
										<div class="col-md-4">
											<span class="span-placeholder" for="tiempoEmpleo">Tipo de empleo *</span>
											<select name="cars" id="tiempoEmpleo" v-model="anterior.tipo" class="vs-input">
												<option :value="''">Seleccione...</option>
												<option :value="tipo.Id" v-for="tipo in tiposDeEmpleo" :key="">{{ tipo.Nombre }}</option>
											</select>
										</div>
									</div>
									<br />
									<button class="btn btn-second" @click="registraEmpleoAnterior()">Guardar empleo anterior</button>
								</div>
							</div>
						</div>
					</div>

					<br />
					<button class="btn btn-second" @click="registraIngreso()" :disabled="!validateForm" v-on:click="actualiza_bandera('add',true)">Guardar ingreso</button>
				</vx-card>
				<vx-card title="" class="">
					<vs-table :data="ingresos" max-items="5" pagination noDataText="No hay datos para mostrar">
						<template slot="header">
							<h3>Lista de Ingresos</h3>
						</template>
						<template slot="thead">
							<vs-th> Empresa </vs-th>
							<vs-th> Tipo de empleo </vs-th>
							<vs-th> Ingresos mensuales </vs-th>
							<vs-th> Ingresos netos </vs-th>
							<vs-th> Acciones </vs-th>
						</template>

						<template slot-scope="{ data }">
							<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
								<vs-td :data="data[indextr].Empresa">
									{{ data[indextr].Empresa }}
								</vs-td>

								<vs-td :data="data[indextr].TipoEmpleo">
									{{ data[indextr].TipoEmpleo }}
								</vs-td>

								<vs-td :data="data[indextr].IngresosMensuales">
									{{ numberFormat(data[indextr].IngresosMensuales) }}
								</vs-td>

								<vs-td :data="data[indextr].IngresosNetos">
									{{ numberFormat(data[indextr].IngresosNetos) }}
								</vs-td>
								<vs-td>
									<vs-row vs-w="12">
										<vx-tooltip text="Editar ingreso" position="top">
											<button class="favoritos" @click="editar(data[indextr])">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
													<g id="vuesax_twotone_edit" data-name="vuesax/twotone/edit" transform="translate(-620 -252)">
														<g id="edit">
															<path id="Vector" d="M9,0H7C2,0,0,2,0,7v6c0,5,2,7,7,7h6c5,0,7-2,7-7V11" transform="translate(622 254)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4" />
															<path id="Vector-2" data-name="Vector" d="M8.991,1.271l-7.88,7.88a2.712,2.712,0,0,0-.66,1.32l-.43,3.01a1.424,1.424,0,0,0,1.7,1.7l3.01-.43a2.8,2.8,0,0,0,1.32-.66l7.88-7.88c1.36-1.36,2-2.94,0-4.94S10.351-.089,8.991,1.271Z" transform="translate(627.049 253.749)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
															<path id="Vector-3" data-name="Vector" d="M0,0A7.144,7.144,0,0,0,4.94,4.94" transform="translate(634.91 256.15)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4" />
															<path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(620 252)" fill="none" opacity="0" />
														</g>
													</g>
												</svg>
											</button>
										</vx-tooltip>
										<vx-tooltip text="Borrar ingreso" position="top">
											<button class="favoritos" @click="eliminar(data[indextr].Id)" v-on:click="actualiza_bandera('delete',false)">
												<svg class="trash">
													<g id="trash">
														<path id="Vector" d="M18,.5C14.67.17,11.32,0,7.98,0A59.068,59.068,0,0,0,2.04.3L0,.5" transform="translate(3 5.48)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
														<path id="Vector-2" data-name="Vector" d="M0,2.97.22,1.66C.38.71.5,0,2.19,0H4.81C6.5,0,6.63.75,6.78,1.67L7,2.97" transform="translate(8.5 2)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
														<path id="Vector-3" data-name="Vector" d="M13.7,0l-.65,10.07c-.11,1.57-.2,2.79-2.99,2.79H3.64C.85,12.86.76,11.64.65,10.07L0,0" transform="translate(5.15 9.14)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
														<path id="Vector-4" data-name="Vector" d="M0,0H3.33" transform="translate(10.33 16.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
														<path id="Vector-5" data-name="Vector" d="M0,0H5" transform="translate(9.5 12.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
														<path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
													</g>
												</svg>
											</button>
										</vx-tooltip>
									</vs-row>
								</vs-td>
							</vs-tr>
						</template>
					</vs-table>
				</vx-card>
			</div>
		</div>
		<vs-popup fullscreen title="Editar Ingresos" :active.sync="popupActive">
			<vx-card title="Datos requeridos" class="">
				<div class="row w-full">
					<div class="col-md-4">
						<span class="span-placeholder">Ingreso mensual*</span>
						<input class="vs-input" v-model="ingreso.ingresoMensual" name="username" @change="formateaIngresoMensual()" v-on:keyup="validaIngersoMensual()" v-on:keypress="isNumber($event)" />
					</div>
					<div class="col-md-4">
						<span class="span-placeholder">Ingresos netos*</span>
						<input class="vs-input" v-model="ingreso.ingresoNeto" name="name" v-on:keypress="isNumber($event)" @change="formateaIngresoNeto()" v-on:keyup="validaIngresoNeto()" />
					</div>
					<div class="col-md-4">
						<span class="span-placeholder">Empresa*</span>
						<input class="vs-input" v-model="ingreso.empresa" name="username" />
					</div>
					<div class="col-md-4">
						<span class="span-placeholder" for="tiempoEmpleo">Tipo de empleo *</span>
						<select name="cars" id="tiempoEmpleo" v-model="ingreso.tipoEmpleo" class="vs-input">
							<option :value="''">Seleccione...</option>
							<option :value="tipo.Id" v-for="tipo in tiposDeEmpleo" :key="">{{ tipo.Nombre }}</option>
						</select>
					</div>
					<div class="col-md-4">
						<span class="span-placeholder">Fecha de inicio</span>
						<input class="vs-input" type="date" v-model="ingreso.fechaInicio" name="fechaNacimiento" />
					</div>
				</div>
				<br />
				<h4>Adicionales</h4>
				<div class="row w-full">
					<div class="col-md-4">
						<span class="span-placeholder">Domicilio (calle )</span>
						<input class="vs-input" v-model="ingreso.domicilio" name="name" />
					</div>
					<div class="col-md-4">
						<span class="span-placeholder">Número exterior</span>
						<input class="vs-input" v-model="ingreso.numeroExterior" name="name" />
					</div>
					<div class="col-md-4">
						<span class="span-placeholder">Número interior</span>
						<input class="vs-input" v-model="ingreso.numeroInterior" name="name" />
					</div>
					<div class="col-md-4">
						<span class="span-placeholder">Código postal</span>
						<input class="vs-input" v-model="ingreso.codigoPostal" name="name" v-on:blur="getColoniaMunicipio(1)"/>
					</div>
					<div class="col-md-4">
						<span class="span-placeholder">Municipio</span>
						<input class="vs-input" v-model="ingreso.municipio" name="name" disabled/>
					</div>					
				</div>
				<div class="row w-full">
					<div class="col-md-4">
							<span class="span-placeholder" v-if="LabelColoniaObligado">Colonia *</span>
                    		<span class="span-placeholder" v-if="LabelColoniaNoObligado">Colonia </span>
							<select class="vs-input" v-model="ingreso.colonia" name="name" id="colonia">
								<option value="" selected>Selecciona una colonia</option>
								<option :value='colonia' v-for="colonia in ColoniasCP" :key="">{{colonia}}</option>
							</select>
						<span class="text-danger span-placeholder" v-if="LabelColoniaObligadoText">Campo requerido</span>
					</div>
					<div class="col-md-4">
						<span class="span-placeholder">Ciudad</span>
						<input class="vs-input" v-model="ingreso.ciudad" name="username" />
					</div>					
					<div class="col-md-4">
						<span class="span-placeholder">Teléfono (10 dígitos)</span>
						<input class="vs-input" v-model="ingreso.telefono" name="name" />
					</div>
				</div>
				<div class="row w-full">
					<div class="col-md-4">
						<span class="span-placeholder" for="formasEnganche">Estado</span>
						<select name="cars" id="formasEnganche" v-model="ingreso.estado" class="vs-input">
							<option :value="tipo.Id" v-for="tipo in estados" :key="">{{ tipo.Nombre }}</option>
						</select>
					</div>
					<div class="col-md-4">

						<span class="span-placeholder">País</span>
						<select 
							name="name" 
							v-model="ingreso.pais" 
							class="vs-input"
						>
							<option :value='pais.Nombre' v-for="pais in paisesNac" :key="">{{ pais.Nombre }}</option>
						</select>
						<!-- <input class="vs-input" v-model="ingreso.pais" name="name" /> -->

					</div>
					<div class="col-md-4">
						<span class="span-placeholder">Puesto</span>
						<input class="vs-input" v-model="ingreso.puesto" name="name" />
					</div>
				</div>
				<div class="row w-full">
					<div class="col-md-4">
						<span class="span-placeholder">Área o departamento</span>
						<input class="vs-input" v-model="ingreso.area" name="area" v-validate="'max:32'" />
						<span class="text-danger span-placeholder" v-show="errors.has('area')">Máximo 32 caracteres, son {{ ingreso.area.length }}</span>
					</div>
					<div class="col-md-4">
						<span class="span-placeholder" for="formasEnganche">Tipo de Contrato</span>
						<select name="cars" id="formasEnganche" v-model="ingreso.tipoContrato" class="vs-input">
							<option :value="''">Seleccione...</option>
							<option :value="tipo.Id" v-for="tipo in tiposContratos" :key="">{{ tipo.Nombre }}</option>
						</select>
					</div>
					<div class="col-md-4">
						<span class="span-placeholder" for="formasEnganche">Giro</span>
						<select name="cars" id="formasEnganche" v-model="ingreso.giro" class="vs-input">
							<option :value="''">Seleccione...</option>
							<option :value="tipo.Id" v-for="tipo in giros" :key="">{{ tipo.Nombre }}</option>
						</select>
					</div>
				</div>
				<div class="row w-full">
					<div class="col-md-4">
						<span class="span-placeholder">Retiene impuestos</span>
						<vs-checkbox v-model="ingreso.impuestos"></vs-checkbox>
					</div>
					<div class="col-md-4">
						<span class="span-placeholder" for="formasEnganche">Sector laboral</span>
						<select name="cars" id="formasEnganche" v-model="ingreso.sector" class="vs-input">
							<option :value="''">Seleccione...</option>
							<option :value="tipo.Id" v-for="tipo in sectores" :key="">{{ tipo.Nombre }}</option>
						</select>
					</div>
					<div class="col-md-4">
						<span class="span-placeholder">Profesión</span>
						<input class="vs-input" v-model="ingreso.profesion" name="name" />
					</div>
				</div>
				<div class="row w-full">
					<div class="col-md-4">
						<span class="span-placeholder" for="formasEnganche">Comprueba ingresos con</span>
						<select name="cars" id="formasEnganche" v-model="ingreso.comprobanteIngresos" class="vs-input">
							<option :value="''">Seleccione...</option>
							<option :value="tipo.Id" v-for="tipo in comprobantes" :key="">{{ tipo.Nombre }}</option>
						</select>
					</div>
					<div class="col-md-4">
						<span class="span-placeholder">Jefe inmediato</span>
						<input class="vs-input" v-model="ingreso.jefe" name="name" />
					</div>
					<div class="col-md-4">
						<span class="span-placeholder">Funciones del empleo</span>
						<input class="vs-input" v-model="ingreso.funciones" name="name" />
					</div>
				</div>
				<br />
				<div class="row w-full">
					<div class="col-md-4">
						<vs-button color="primary" @click="editarIngreso()" :disabled="!validateForm">Editar ingreso</vs-button>
					</div>
				</div>
			</vx-card>
		</vs-popup>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import { paisesNacimiento } from '@/assets/jsonFiles/paisesNacimiento.js'
import { municipios } from '@/assets/jsonFiles/municipios.js'
export default {
	components: {
		'v-select': vSelect,
	},
	data() {
		return {
			popupActive: false,
			paisesNac: [],
			municipioDom : [],
			key: '',
			ingreso: {
				ingresoId: '',
				ingresoNeto: '',
				ingresoMensual: '',
				tipoEmpleo: '',
				empresa: '',
				fuente: '',
				puesto: '',
				giro: '',
				tipoContrato: '',
				impuestos: false,
				fechaInicio: '',
				domicilio: '',
				colonia: '',
				numeroExterior: '',
				numeroInterior: '',
				ciudad: '',
				municipio: '',
				codigoPostal: '',
				telefono: '',
				area: '',
				sector: '',
				profesion: '',
				comprobanteIngresos: '',
				estado: '',
				pais: '',
				jefe: '',
			},
			anterior: {
				empresaAnterior: '',
				inicioAnterior: '',
				salidaAnterior: '',
				separacion: '',
				funciones: '',
				telefono: '',
				jefe: '',
				contrato: '',
				tipo: '',
				sector: '',
			},
			solicitante: {},
			ingresos: [],
			tiposDeEmpleo: [],
			giros: [],
			estados: [],
			comprobantes: [],
			tiposContratos: [],
			sectores: [],
			id: '',
			Id:'',
			email: '',
			fechaSinValidar: false,
      		state_data: 1,
			AccreditedidV3 : false,
			// header: {
			// 		"authorization": "Bearer 16JXvQ9CD1eEoVESY3-4d-bElrAAgAfcB0UgYwAB-XyuNugkcBvgntr6n6VjEAFWenS9Exi_jlLv_RBfmf004TXytHP64dNEuSyhlXOBDtO56hW07txWd4evNWfukK5CO1ETRIOk8GWyjjS7daQ8eLHUz3XefoKEHKE0yIy76o8rZZYTGskAn1Zo3oKcPFzfs_fmVlO15cD_Kxx27-1qqDBsfAphhY7N599ly5ppo5L4XHaL8JAv6mTxXVYxrTZPQDmnLqQ9Nwre7Bd4URM5rR3rVMrAVkPzJ8ohQ9-pgvigB-Fra_SMi31c5T-QrSTB"
			// 		},
			header: {},
			solicitanteV3 : '',
			SocGuid:'',
			BrokerId:'',
			ColoniasCP: [],
			MunicipiosCP: [],
			LabelColoniaObligado: false,
			LabelColoniaNoObligado: true,
			LabelColoniaObligadoText: true,
			lblTelefonoRequerido: true,
		}
	},
	watch: {
		/*ingresoNeto: function(newValue) {
      this.ingreso.ingresoNeto= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    ingresoMensual: function(newValue) {
      this.ingreso.ingresoMensual= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },*/
		popupActive: function () {
			if (this.popupActive == false) {
				this.limpiaForm()
			}
		},
		'ingreso.telefono': function( newValue, oldValue ) {
			let regex = /^[0-9]{0,10}$/;
			if (!regex.test(newValue)) {
				this.ingreso.telefono = oldValue;
			}

			if (this.ingreso.telefono != '') {
				this.lblTelefonoRequerido = false;
			} else {
				this.lblTelefonoRequerido = true;
			}
		},
	},
	computed: {
		ingresoNeto() {
			return this.ingreso.ingresoNeto
		},
		ingresoMensual() {
			return this.ingreso.ingresoMensual
		},
		validateForm() {
			//return this.ingreso.tipoEmpleo != '' && this.ingreso.ingresoNeto != '' && this.ingreso.ingresoMensual != '' && this.ingreso.empresa != '' && ((this.ingreso.tipoEmpleo != 48 && this.ingreso.fechaInicio != '') || this.ingreso.tipoEmpleo == 48)
			if (this.ingreso.codigoPostal == '') {
				this.LabelColoniaObligado = false
				this.LabelColoniaNoObligado = true
				this.LabelColoniaObligadoText = false
				return this.ingreso.tipoEmpleo != '' && this.ingreso.ingresoNeto != '' && this.ingreso.ingresoMensual != '' && this.ingreso.empresa != '' && this.ingreso.telefono != '' && ((this.ingreso.tipoEmpleo != 48 && this.ingreso.fechaInicio != '') || this.ingreso.tipoEmpleo == 48)
			}else{
				this.LabelColoniaObligado = true
				this.LabelColoniaNoObligado = false
				if (this.ingreso.colonia) {
				this.LabelColoniaObligadoText = false
				}
				else
				this.LabelColoniaObligadoText = true

				return this.ingreso.tipoEmpleo != '' && this.ingreso.ingresoNeto != '' && this.ingreso.ingresoMensual != '' && this.ingreso.empresa != '' && ((this.ingreso.tipoEmpleo != 48 && this.ingreso.fechaInicio != '') || this.ingreso.tipoEmpleo == 48) && this.ingreso.colonia != ''
			}
		},
	},
	mounted() {
		this.id = this.$route.params.id
		this.getPaisesNacimiento()
		this.getMunicipios();
		this.getApikey()
		this.getEstados()
		this.getListaTipoEmpleos()
		this.getTipoIdentificaciones()
		this.getGiros()
		this.getSectoresRed()
		this.getTiposContratos()
		this.dameCaso()
		this.DameCredenciales()
	},
	methods: {
		async getAuthToken() {
			let objRequestToken = {
				strApiKey: this.key,
				strMetodo: 'DameAuthToken',
			}
			await this.$axios.post('/', objRequestToken,
			{ headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
			.then( response => {
			if (!response.data.error) {
				this.header = { "Authorization" : "Bearer "+ response.data.access_token};
			} else {
				this.$vs.notify({
				title: 'Ocurrio un error al obtener token',
				text: response.data.error_description,
				color: 'danger',
				position: 'top-right',
				})
			}
			}).catch(function (error) {
			self.$vs.notify({
				title: 'Ocurrio un error de sistema',
				text: error,
				color: 'danger',
				position: 'top-right',
			})
			});
		},
		actualiza_bandera: function(Origen, status) {
			this.$emit('actualiza_bandera', Origen, status)
		},
		getPaisesNacimiento() {
			this.paisesNac = paisesNacimiento;
		},
		getMunicipios() {
			this.municipioDom = municipios;
		},
		validaIngresoNeto() {
			this.ingreso.ingresoNeto = this.ingreso.ingresoNeto.replace(/,/g, '')
			const regex = /^(\d{1,8})(\.\d{0,2})?$/
			const matches = regex.test(this.ingreso.ingresoNeto)
			if (matches === true) {
				this.ingreso.ingresoNeto = this.ingreso.ingresoNeto
			} else {
				this.ingreso.ingresoNeto = this.ingreso.ingresoNeto.replace(/.$/, '')
			}
		},
		formateaIngresoNeto() {
			if (this.ingreso.ingresoNeto == '') {
				this.ingreso.ingresoNeto = 0
			}
			this.ingreso.ingresoNeto = parseFloat(this.ingreso.ingresoNeto).toFixed(2)
			const exp = /(\d)(?=(\d{3})+(?!\d))/g
			const rep = '$1,'
			this.ingreso.ingresoNeto = this.ingreso.ingresoNeto.toString().replace(exp, rep)
		},
		validaIngersoMensual() {
			this.ingreso.ingresoMensual = this.ingreso.ingresoMensual.replace(/,/g, '')
			const regex = /^(\d{1,8})(\.\d{0,2})?$/
			const matches = regex.test(this.ingreso.ingresoMensual)
			if (matches === true) {
				this.ingreso.ingresoMensual = this.ingreso.ingresoMensual
			} else {
				this.ingreso.ingresoMensual = this.ingreso.ingresoMensual.replace(/.$/, '')
			}
		},
		formateaIngresoMensual() {
			if (this.ingreso.ingresoMensual == '') {
				this.ingreso.ingresoMensual = 0
			}
			this.ingreso.ingresoMensual = parseFloat(this.ingreso.ingresoMensual).toFixed(2)
			const exp = /(\d)(?=(\d{3})+(?!\d))/g
			const rep = '$1,'
			this.ingreso.ingresoMensual = this.ingreso.ingresoMensual.toString().replace(exp, rep)
		},
		numberFormat(num) {
			return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
		registraEmpleoAnterior() {
			var objEmpleoAnterior = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraSolicitanteEmpleoAnterior',
				objSolicitante: {
					EMail: this.email,
					Empleo: {
						NombreEmpleoAnterior: this.anterior.empresaAnterior,
						JefeEmpleoAnterior: this.anterior.jefe,
						TelefonoEmpleoAnterior: this.anterior.telefono,
						CausaSeparacionEmpleoAnterior: this.anterior.separacion,
						FechaIngresoEmpleoAnterior: this.anterior.inicioAnterior,
						FechaSalidaEmpleoAnterior: this.anterior.salidaAnterior,
						DescripcionFuncionesLaborales: this.anterior.funciones,
						TipoContratoEmpleoAnterior: this.anterior.contrato,
						TipoEmpleoAnterior: this.anterior.tipo,
						SectorRedHipotecariaEmpleoAnterior: this.anterior.sector,
					},
				},
			}
			this.$axios
				.post('/', objEmpleoAnterior, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Datos guardados exitosamente`,
							position: 'top-right',
						})
						this.dameSolicitante()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraSolicitanteEmpleoAnterior',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		editarIngreso() {
			let self = this
			let municipioSelect = 0;			
			if (this.ingreso.estado) {
				let municipiosIngreso = this.municipioDom[this.ingreso.estado - 1]
				
				for (let index = 0; index < municipiosIngreso.length; index++) {							
					if (municipiosIngreso[index].Nombre === this.ingreso.municipio.toUpperCase()) {									
						municipioSelect = municipiosIngreso[index].Nombre;
					}				
				}	
			}			
			
			
			var objRequestRegistraCuentasBancarias = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'EditarIngresos',
				objSolicitante: {
					EMail: this.email,
					Ingresos: [
						{
							Id: this.ingreso.ingresoId,
							IngresosMensuales: parseFloat(this.ingreso.ingresoMensual.replace(/,/g, '')),
							IngresosNetos: parseFloat(this.ingreso.ingresoNeto.replace(/,/g, '')),
							TipoEmpleo: this.ingreso.tipoEmpleo,
							Empresa: this.ingreso.empresa,
							Fuente: this.ingreso.fuente,
							Puesto: this.ingreso.puesto,
							Giro: this.ingreso.giro,
							TipoContrato: this.ingreso.tipoContrato,
							RetieneImpuestos: this.ingreso.impuestos,
							FechaInicio: this.ingreso.fechaInicio,
							Domicilio: this.ingreso.domicilio,
							Colonia: this.ingreso.colonia,
							NumeroExterior: this.ingreso.numeroExterior,
							NumeroInterior: this.ingreso.numeroInterior,
							Ciudad: this.ingreso.ciudad,
							Municipio: municipioSelect,
							CodigoPostal: this.ingreso.codigoPostal,
							Telefono: this.ingreso.telefono,
							AreaDepartamento: this.ingreso.area,
							SectorRedHipotecaria: this.ingreso.sector,
							Profesion: this.ingreso.profesion,
							ComprobanteIngresos: this.ingreso.comprobanteIngresos,
							Estado: this.ingreso.estado,
							// Pais: this.ingreso.pais,
							Pais: {
								name: this.ingreso.pais,
								noSanitiza: true,
							},
							JefeInmediatoNombre: this.ingreso.jefe,
						},
					],
					Empleo: {
						ActividadesEmpleoActual: this.ingreso.funciones,
					},
				},
			}
			this.$axios
				.post('/', objRequestRegistraCuentasBancarias, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Datos editados exitosamente`,
							position: 'top-right',
						})
						this.popupActive = false
						this.limpiaForm()
						this.dameSolicitante()
						if (this.AccreditedidV3) { //si el solicitante cuenta con pedidos en v3 entonces actualiza inf en liga cliente.
							this.generaInformacionParaV3(); 
						}
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraCuentasBancarias',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
				
		},
		editar(ingreso) {
			this.ingreso.ingresoId = ingreso.Id
			this.ingreso.tipoEmpleo = ingreso.TipoEmpleoId
			this.ingreso.ingresoNeto = this.numberFormat(ingreso.IngresosNetos)
			this.ingreso.ingresoMensual = this.numberFormat(ingreso.IngresosMensuales)
			this.ingreso.empresa = ingreso.Empresa
			this.ingreso.fuente = ingreso.Fuente
			this.ingreso.puesto = ingreso.Puesto
			this.ingreso.giro = ingreso.GiroId
			this.ingreso.tipoContrato = ingreso.TipoContratoId
			this.ingreso.impuestos = ingreso.RetieneImpuestos
			this.ingreso.fechaInicio = ingreso.FechaInicio
			this.ingreso.domicilio = ingreso.Domicilio
			this.ingreso.colonia = ingreso.Colonia
			this.ingreso.numeroExterior = ingreso.NumeroExterior
			this.ingreso.numeroInterior = ingreso.NumeroInterior
			this.ingreso.ciudad = ingreso.Ciudad
			this.ingreso.municipio = ingreso.Municipio
			this.ingreso.codigoPostal = ingreso.CodigoPostal
			this.ingreso.telefono = ingreso.Telefono
			if (ingreso.AreaDepartamento) {
				this.ingreso.area = ingreso.AreaDepartamento
			} else {
				this.ingreso.area = ''
			}
			this.ingreso.sector = ingreso.SectorRedHipotecariaId
			this.ingreso.profesion = ingreso.Profesion
			this.ingreso.comprobanteIngresos = ingreso.ComprobanteIngresosId
			this.ingreso.estado = ingreso.EstadoId
			this.ingreso.pais = ingreso.Pais
			this.ingreso.jefe = ingreso.JefeInmediatoNombre
			this.popupActive = true
			if (ingreso.CodigoPostal) {
				this.getColoniaMunicipio(0);  
			}
		},
		eliminar(id) {
			var eliminarCuentas = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'BorrarIngresos',
				objSolicitante: {
					EMail: this.email,
					Ingresos: [
						{
							Id: id,
						},
					],
				},
			}
			this.$axios
				.post('/', eliminarCuentas, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Se eliminó exitosamente`,
							position: 'top-right',
						})
						if (this.AccreditedidV3) { //si el solicitante cuenta con pedidos en v3 entonces actualiza inf en liga cliente.
							this.generaInformacionParaV3();
						}
						
						this.dameSolicitante()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en BorrarIngresos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		registraIngreso() {
			this.$vs.loading({
				container: '#container',
				scale: 0.6,
			})
			let self = this
			var objRequestListaCasos = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'RegistraIngresos',
				objSolicitante: {
					EMail: this.email,
					Ingresos: [
						{
							IngresosMensuales: parseFloat(this.ingreso.ingresoMensual.replace(/,/g, '')),
							IngresosNetos: parseFloat(this.ingreso.ingresoNeto.replace(/,/g, '')),
							TipoEmpleo: this.ingreso.tipoEmpleo,
							Empresa: this.ingreso.empresa,
							Fuente: this.ingreso.fuente,
							Puesto: this.ingreso.puesto,
							Giro: this.ingreso.giro,
							TipoContrato: this.ingreso.tipoContrato,
							RetieneImpuestos: this.ingreso.impuestos,
							FechaInicio: this.ingreso.fechaInicio,
							Domicilio: this.ingreso.domicilio,
							Colonia: this.ingreso.colonia,
							NumeroExterior: this.ingreso.numeroExterior,
							NumeroInterior: this.ingreso.numeroInterior,
							Ciudad: this.ingreso.ciudad,
							Municipio: this.ingreso.municipio,
							CodigoPostal: this.ingreso.codigoPostal,
							Telefono: this.ingreso.telefono,
							AreaDepartamento: this.ingreso.area,
							SectorRedHipotecaria: this.ingreso.sector,
							Profesion: this.ingreso.profesion,
							ComprobanteIngresos: this.ingreso.comprobanteIngresos,
							Estado: this.ingreso.estado,
							// Pais: this.ingreso.pais,
							Pais: {
								name: this.ingreso.pais,
								noSanitiza: true,
							},
							JefeInmediatoNombre: this.ingreso.jefe,
						},
					],
					Empleo: {
						ActividadesEmpleoActual: this.ingreso.funciones,
					},
				},
			}
			this.$axios
				.post('/', objRequestListaCasos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: `Datos guardados exitosamente`,
							position: 'top-right',
						})
						this.limpiaForm()
						this.dameSolicitante()
						if (this.AccreditedidV3) { //si el solicitante cuenta con pedidos en v3 entonces actualiza inf en liga cliente.
							this.generaInformacionParaV3(); 
						}
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en RegistraIngresos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
					this.$vs.loading.close('#container > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		generaInformacionParaV3(){
			let self=this
			var objRequestDameInfo = {
				strFolioCaso: this.id,
			strApiKey: this.key,
			strMetodo: 'generaInformacionParaV3',
			objSolicitante:
			{
				EMail: this.email
			},
			objCaso: {
			Id: this.Id,
			}
			}
			this.$axios.post('/',objRequestDameInfo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
			response => {
				if(response.data.intEstatus == 0){
				this.solicitanteV3 = response.data.objContenido
				this.updateInfoEnV3()
				}else {
				this.$vs.notify({
					title:'Ocurrio un error en generaInformacionParaV3',
					text:response.data.strError,
					color:'danger',
					position:'top-right'
				})
				}
			}
			).catch(function (error) {
			self.$vs.notify({
				title:'Ocurrio un error de sistema',
				text:error,
				color:'danger',
				position:'top-right'
			})
			})
		},
		async updateInfoEnV3(){
			await this.getAuthToken();
			
			let self=this
				
			var mostrar = ""
			let objRequestUpdateInfo =
			{
			IsQuickQuote:false,
			AccreditedModel: {
				Name: this.solicitanteV3.Nombres,
				SName: this.solicitanteV3.SegundoNombre,
				LastName: this.solicitanteV3.ApellidoPaterno,
				SecondLastName: this.solicitanteV3.ApellidoMaterno,
				PersonalMail: this.email,
				PhoneHome: this.solicitanteV3.TelefonoCasa,
				CellPhone: this.solicitanteV3.TelefonoCelular,
				DateBirtday: this.solicitanteV3.FechaNacimiento,
				Gender: this.solicitanteV3.Genero,
				Nationality: this.solicitanteV3.Nacionalidad,
				CURP: this.solicitanteV3.CURP,
				RFC: this.solicitanteV3.RFC,
				Weight: this.solicitanteV3.Peso,
				Height: this.solicitanteV3.Estatura,
				NSS: this.solicitanteV3.Nss,
				StudyGrade: this.solicitanteV3.GradoEstudios,
				CivilStatus: this.solicitanteV3.EstadoCivil,
				EconomicDependents: this.solicitanteV3.DependientesEconomicos,
				NumberOfChildren: this.solicitanteV3.NumeroHijos,
				ChildrensAge: this.solicitanteV3.EdadHijos,
				Cocredited: this.solicitanteV3.Coacreditado,
				//domicilio
				TypeHousing: this.solicitanteV3.TipoInmueble,
				ResidenceTimeHome: this.solicitanteV3.TiempoResidenciaActual,
				ResidenceTimeTown: this.solicitanteV3.TiempoResidenciaPoblacion,
				CP: this.solicitanteV3.CodigoPostal,
				//Bienes inmuebles
				NumberOfCars: this.solicitanteV3.NumeroAutos,				
				//Ingresos
				Activity: this.solicitanteV3.Sector,
				TypeCompany: this.solicitanteV3.TipoEmpleo,
				CompanyName: this.solicitanteV3.Empresa,
				CompanyAddress: this.solicitanteV3.DomicilioEmpresa,
				MonetaryIncome: this.solicitanteV3.Ingresos,
				Position: this.solicitanteV3.Puesto,
				DateBeginWork: this.solicitanteV3.FechaInicio,

				Age: this.solicitanteV3.Edad,		
			},
			ReferencesModel: {
				Name: this.solicitanteV3.NombreReferencia,
				SName: null,
				LastName: this.solicitanteV3.ApellidoPaternoRef,
				SecondLastName: this.solicitanteV3.ApellidoMaternoRef,
				Phone: this.solicitanteV3.TelCelularRef,
				Relationship: this.solicitanteV3.ParentescoRef,
				TimeRelationship: this.solicitanteV3.AnosConocerloRef
			},
			CreditRequestModel: {
				TypeCredit: null,
				HomeValue: null,
				CreditAmount: null,
				Plazo: null
			},
			BrokerEmployeeModel: {
				Id: this.SocGuid,
          		BrokerId: this.BrokerId,
					//para pruebas comentar las dos de arriba, terminando descomentar arriba y commentar abajo
					//Id: "61711b5c-948c-4928-94f0-158b96b58da0",
					//BrokerId:"ca1b7cbd-f2a5-4507-9f03-4c167dcb2663",
				EmployeeName: null,
				EmployeeEmail: null,
				Origin: "Cotizador soc"
				
			},
			MaterialGoodsModel:{
				Properties:{
					NumberProperties: this.solicitanteV3.NumeroInmuebles,
					ApproximateValue: this.solicitanteV3.ValorAproximado,
					EncumberedValue: this.solicitanteV3.ValorGrabado,
				},
				CarOne:{
					ApproximateValueCar: this.solicitanteV3.ValorAuto1,
					MarkCar: this.solicitanteV3.MarcaAuto1,
					ModelCar: this.solicitanteV3.ModeloAuto1,
					YearCar: this.solicitanteV3.AnioAuto1,
				},
				CarTwo:{
					ApproximateValueCar: this.solicitanteV3.ValorAuto2,
					MarkCar: this.solicitanteV3.MarcaAuto2,
					ModelCar: this.solicitanteV3.ModeloAuto2,
					YearCar: this.solicitanteV3.AnioAuto2
				}
			}

			
			}   
			this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/External/CreateAccredited',objRequestUpdateInfo,{headers : this.header})
				.then(
				response => {

				}
				).catch(function (error) {
					if(error.response.data.Message == "The CellPhone already exists"){
					mostrar = "El telefono ya se encuentra registrado intente con otro"
					}else if(error.response.data.Message == "The email already exists"){
					mostrar = "El correo elecronico ya se encuentra registrado intente con otro"
					}else{
					mostrar = error
					}
					
					self.$vs.notify({
						title:'Ocurrio un error en SISEC',
						text:mostrar,
						color:'danger',
						position:'top-right'
					})
				})

		},
		DameCredenciales(){
			let self=this
			let objRequestDameCredenciales = {
			strApiKey: this.key,
			strMetodo: 'DameCredenciales',
			objBroker: {
			ApiKey: this.key
			}
			}
			this.$axios.post('/',objRequestDameCredenciales,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
			response => {
			this.SocGuid = response.data.objContenido.SocGuid
			this.BrokerId = response.data.objContenido.BrokerId

			//this.SocGuid = '61711b5c-948c-4928-94f0-158b96b58da0',
          	//this.BrokerId = 'ca1b7cbd-f2a5-4507-9f03-4c167dcb2663';
			}
			).catch(function (error) {
			self.$vs.notify({
			title:'Ocurrio un error de sistema',
			text:error,
			color:'danger',
			position:'top-right'
			})
			})
		},
		dameCaso() {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.email = response.data.objContenido.Solicitante
						this.Id = response.data.objContenido.Id
						this.dameSolicitante()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameCaso',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		dameSolicitante() {
			let self = this
			var objRequestDameSolicitante = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.email,
				},
			}
			this.$axios
				.post('/', objRequestDameSolicitante, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

          // return console.log(response)

					if (response.data.intEstatus == 0) {
						this.solicitante = response.data.objContenido
            			this.state_data = response.data.objContenido.StateData
						this.ingresos = response.data.objContenido.Ingresos
						this.ingreso.funciones = this.solicitante.DescripcionFuncionesLaborales
						this.anterior.empresaAnterior = this.solicitante.NombreEmpleoAnterior
						this.anterior.inicioAnterior = this.solicitante.FechaIngresoEmpleoAnterior
						this.anterior.salidaAnterior = this.solicitante.FechaSalidaEmpleoAnterior
						this.anterior.separacion = this.solicitante.CausaSeparacionEmpleoAnterior
						this.anterior.jefe = this.solicitante.JefeEmpleoAnterior
						this.anterior.telefono = this.solicitante.TelefonoEmpleoAnterior
						if (this.solicitante.TipoContratoEmpleoAnterior == null) this.anterior.contrato = ''
						else this.anterior.contrato = this.solicitante.TipoContratoEmpleoAnterior

						if (this.solicitante.TipoContratoEmpleoAnterior == null) this.anterior.tipo = ''
						else this.anterior.tipo = this.solicitante.TipoEmpleoAnterior

						if (this.solicitante.TipoContratoEmpleoAnterior == null) this.anterior.sector = ''
						else this.anterior.sector = this.solicitante.SectorRedHipotecariaEmpleoAnterior

						if (this.solicitante.AccreditedIdV3 !=null && this.solicitante.AccreditedIdV3 != '') {
							this.AccreditedidV3 = true;
						}
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameSolicitante',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getListaTipoEmpleos() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaTipoEmpleos',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.tiposDeEmpleo = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaTipoEmpleos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getEstados() {
			let self = this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaEstados',
			}
			this.$axios
				.post('/', objRequestListaCasoEstatus, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.estados = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaEstados',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getTipoIdentificaciones() {
			let self = this
			var objRequestListaTipoIdentificaciones = {
				strApiKey: this.key,
				strMetodo: 'ListaComprobantesIngreso',
			}
			this.$axios
				.post('/', objRequestListaTipoIdentificaciones, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.comprobantes = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaTipoIdentificaciones',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getGiros() {
			let self = this
			var objRequestListaGirosIngreso = {
				strApiKey: this.key,
				strMetodo: 'ListaGirosIngreso',
			}
			this.$axios
				.post('/', objRequestListaGirosIngreso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.giros = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaGirosIngreso',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getTiposContratos() {
			let self = this
			var objRequestListaTipoContratos = {
				strApiKey: this.key,
				strMetodo: 'ListaTipoContratos',
			}
			this.$axios
				.post('/', objRequestListaTipoContratos, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.tiposContratos = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaTipoContratos',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		getSectoresRed() {
			let self = this
			var objRequestListaSectorRedHipotecaria = {
				strApiKey: this.key,
				strMetodo: 'ListaSectorRedHipotecaria',
			}
			this.$axios
				.post('/', objRequestListaSectorRedHipotecaria, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.sectores = response.data.objContenido
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en ListaSectorRedHipotecaria',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		limpiaForm() {
			this.ingreso.tipoEmpleo = ''
			this.ingreso.ingresoNeto = ''
			this.ingreso.ingresoMensual = ''
			this.ingreso.empresa = ''
			this.ingreso.fuente = ''
			this.ingreso.puesto = ''
			this.ingreso.giro = ''
			this.ingreso.tipoContrato = ''
			this.ingreso.impuestos = false
			this.ingreso.fechaInicio = ''
			this.ingreso.domicilio = ''
			this.ingreso.colonia = ''
			this.ingreso.numeroExterior = ''
			this.ingreso.numeroInterior = ''
			this.ingreso.ciudad = ''
			this.ingreso.municipio = ''
			this.ingreso.codigoPostal = ''
			this.ingreso.telefono = ''
			this.ingreso.area = ''
			this.ingreso.sector = ''
			this.ingreso.profesion = ''
			this.ingreso.comprobanteIngresos = ''
			this.ingreso.estado = ''
			this.ingreso.pais = ''
			this.ingreso.jefe = ''
			this.ingreso.funciones = ''
			// this.ingreso.empresaAnterior=''
			// this.ingreso.duracionAnios=''
			// this.ingreso.duracionMeses=''
		},
		getApikey() {
			if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
				this.$router.push('/login')
			} else {
				this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
			}
		},
		isNumber: function (evt) {
			evt = evt ? evt : window.event
			var charCode = evt.which ? evt.which : evt.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault()
			} else {
				return true
			}
		},
		async getColoniaMunicipio(value){
			var origen = 0;

			if (value == 1) {
				this.ingreso.colonia = '';	
				origen = 1;
			}
			
			const getToken = await this.getTokenSisec();
			var token = getToken.objRespuesta.token
			const dataInfoCP = await this.getInformationCP(token)

			if ( !dataInfoCP ) {
				this.ingreso.colonia = ''
				this.ingreso.municipio = ''
				return
			}
			
			const colonias = dataInfoCP.map(x => x.colonia)
			this.ColoniasCP = colonias
			this.ingreso.municipio = dataInfoCP[0].municipio

			if (origen == 0) {
				if(colonias.indexOf(this.ingreso.colonia) == -1){  // no se encontro
					this.ingreso.colonia = '';	
				}
			}

		
		},
		async getTokenSisec() {
			const URL_Method = 'https://cotizador.socasesores.com/consultaSisec/getTokenSisec.php'

			const { data, status } = await this.$axios
						.post(URL_Method,{headers : {'Content-Type':'application/json; charset=UTF-8'}})

			if ( status !== 200 ) {

				this.$vs.notify({
				title: 'Ocurrio un error en getTokenSisec',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return false

			}

			if ( data.intRespuesta ) {

				this.$vs.notify({
				title: 'Ocurrio un error en Metodo getTokenSisec',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return false

			}

			if ( !data.objRespuesta.status ) {

				this.$vs.notify({
				title: 'Ocurrio un error en API getTokenSisec',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return false

			}

			//console.log(data)
			return data;

		},
		async getInformationCP(token) {

				const jsonCP = {
									token,
									CodigoPostal: this.ingreso.codigoPostal
								}

				const URL_Method = 'https://cotizador.socasesores.com/consultaSisec/getInfoCP.php'

				const { status, data } = await this.$axios
						.post(URL_Method,jsonCP,{headers : {'Content-Type':'application/json; charset=UTF-8'}})

				if ( status !== 200 ) {

					this.$vs.notify({
						title: 'Ocurrio un error en getInformationCP',
						text: data.strError,
						color: 'danger',
						position: 'top-right',
					})

					return false

				}

				if ( data.intRespuesta ) {

					this.$vs.notify({
						title: 'Ocurrio un error en Metodo getInformationCP',
						text: data.strError,
						color: 'danger',
						position: 'top-right',
					})

					return false

				}

				if ( data.objRespuesta.message ) {

					this.$vs.notify({
					title: data.objRespuesta.message,
					text: data.strError,
					color: 'danger',
					position: 'top-right',
					})

					return false

				}

				if ( !data.objRespuesta.length ) {
					return false
				}

				return data.objRespuesta

		}
	},
}
</script>

<style lang="css" scoped>
.v-select{
    width: 100%;
}
.label-select{
  margin-top: 3%;
}
.con-vs-checkbox{
  margin-top: 5%;
}
</style>
