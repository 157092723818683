<template>
  <div>
  
    <div id="sidebarMenu" >
       <div class="arrow_right">
        <img src="../../assets/images/pages/arrow_right.png" id="arrow_right" width="40">
      </div>
      <div  class="sidebarMenuInner" ><!--aquí va un label-->
      <div class=" sublink ">
        <a href="https://cotizador.socasesores.com/app/"  >Lista de casos</a>
        <!--<router-link to="/" class="sublink" >Lista de casos</router-link>-->
      </div>
      <div id="impFav" class="sublink">
        <a href="#" @click="functionSaveEvent()">Cotización rapida</a>
        
        <!--<a href="https://cotizador.socasesores.com/app/nuevaCotizacion" >Cotización rápida</a>-->
        <!--<router-link to="/nuevaCotizacion" class="sublink">Cotización rápida</router-link>-->
      </div>
      <!--<div class="sublink">
        <a href="https://cotizador.socasesores.com/app/nuevoCaso">Nuevo caso</a>
      </div>-->
     
      <div class="sublink">
        <a href="https://cotizador.socasesores.com/app/reportesCredito">Reportes de Crédito</a>
      </div>
      <!-- <div class="sublink">
        <a href="https://cotizador.socasesores.com/app/PrecaSoc">Precalificación SOC</a>
      </div>-->

      <!--<div class="dropdown sublink">
            <button class="btn-sublink dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Reportes de Crédito
            </button>
            <div class="dropdown-menu">
              <div class="sublink" style="margin-top:20px;">
                <a class="" href="https://cotizador.socasesores.com/app/reportesCredito">Consulta de Crédito</a>
              </div>
              <div class="sublink">
                
                <a class="" href="https://cotizador.socasesores.com/app/buroCredito">Buro de Crédito</a>
              </div>
            </div>
        </div>-->
        
       <div class="dropdown">
          <button class="btn-sublink dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            Nuevo Caso
          </button>
          <div class="dropdown-menu">
            <div class="sublink" style="margin-top:20px;">
              <a class="" href="https://cotizador.socasesores.com/app/nuevoCaso">Registro Completo</a>
            </div>
            <div class="sublink" v-if="perfilEjecutivo != 1 && perfilEjecutivo != 2 && perfilEjecutivo != 3 &&perfilEjecutivo != 4 && perfilEjecutivo != 5 ">
              <a class="" href="https://cotizador.socasesores.com/app/PrecaSoc">Precalificación SOC</a>
            </div>          
          </div>
        </div>
  
        
        <div class="sublink" style="margin-top:20px;">
              <a class="" href="https://cotizador.socasesores.com/app/AccesoPrecalificador">Acceso de precalificador</a>
       
        </div>
        
       <logout  />
      </div><!--aquí cierra un label-->
     
    </div>
    <div id='center' class="">
      <div class="relative">
        <div class="vx-navbar-wrapper" style="position:fixed;">
  
          <vs-navbar class=" navbar-custom navbar-skelton">
              <input type="checkbox" class="openMovilMenu" id="openMovilMenu" style="display: none;">
                <label for="openMovilMenu" class="movilIconToggle">
                  <div class="spinner2 diagonal part-1"></div>
                  <div class="spinner2 horizontal"></div>
                  <div class="spinner2 diagonal part-2"></div>
                </label>
                <div id="movilMenu">
                  <div class="movilMenuInner ">
                    <br>
                    <div class=" sublink mt-4">
                      <a href="https://cotizador.socasesores.com/app/"  >Lista de casos</a>
                      <!--<router-link to="/" class="sublink" >Lista de casos</router-link>-->
                    </div>
                    <div id="impFav" class="sublink">
                      <a @click="functionSaveEvent()" href="#">Cotización rápida</a>
                      
                      <!--<a href="https://cotizador.socasesores.com/app/nuevaCotizacion" >Cotización rápida</a>-->
                      <!--<router-link to="/nuevaCotizacion" class="sublink">Cotización rápida</router-link>-->
                    </div>
                    <!--<div class="sublink">
                      <a href="https://cotizador.socasesores.com/app/nuevoCaso">Nuevo caso</a>
                    </div>-->
                     <div class="sublink">
                      <a href="https://cotizador.socasesores.com/app/reportesCredito">Reportes de Crédito</a>
                    </div>
                    <!--<div class="sublink">
                      <a href="https://cotizador.socasesores.com/app/PrecaSoc ">Precalificación SOC</a>
                    </div>-->
                    <div class="dropdown">
                      <button class="btn-sublink dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Nuevo Caso
                      </button>
                      <div class="dropdown-menu">
                        <div class="sublink" style="margin-top:20px;">
                          <a class="" href="https://cotizador.socasesores.com/app/nuevoCaso">Registro Completo</a>
                        </div>
                        <div class="sublink">
                          <a class="" href="https://cotizador.socasesores.com/app/PrecaSoc">Precalificación SOC</a>
                        </div>
                      </div>
                    </div>
                    <div class="sublink" style="margin-top:20px;">
                      <a href="https://cotizador.socasesores.com/app/AccesoPrecalificador">Acceso de precalificador</a>
                    </div>
                    <logout  />
                  </div>
                </div>
            <div :text="'Botón de inicio'" class="logo-position" >
              <a class=" header-logo" @click="$router.push('/')">  <img src="../../assets/images/pages/logo-soc-nuevo.png" alt="logo-soc"  style="margin: .5rem; height: 4rem; cursor:pointer;"/></a>
            </div>
            <vs-spacer />
  
            <div class="perfil" :text="'Menú para el perfil'" position="bottom">
              <profile-drop-down />
            </div>
          </vs-navbar>
        </div>
      </div>
      <div class="wrap-all-the-things" id="wrap-all">
          <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
      </div>   
  
    </div>
  
  </div>
</template>


<script>
import Logout      from "@/layouts/components/navbar/components/Logout.vue"
import BackToTop           from 'vue-backtotop'
import HNavMenu            from "@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue"
import navMenuItems        from "@/layouts/components/vertical-nav-menu/navMenuItems.js"
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue'
import TheNavbarVertical   from '@/layouts/components/navbar/TheNavbarVertical.vue'
import TheFooter           from '@/layouts/components/TheFooter.vue'
import themeConfig         from '@/../themeConfig.js'
import VNavMenu            from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import ProfileDropDown      from "@/layouts/components/navbar/components/ProfileDropDown.vue"


export default {
   //router: {
   // linkActiveClass: 'active-sublink'
  //},
  components: {
    Logout,
    BackToTop,
    HNavMenu,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
    ProfileDropDown
  },
  data() {
    return {
      footerType        : themeConfig.footerType  || 'static',
      hideScrollToTop   : themeConfig.hideScrollToTop,
      isNavbarDark      : false,
      navbarColor       : themeConfig.navbarColor || '#fff',
      navbarType        : themeConfig.navbarType  || 'floating',
      navMenuItems      : navMenuItems,
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle,
      key: '',
      perfilEjecutivo: ''
    }
  },
  watch: {
    "$route"() {
      this.routeTitle = this.$route.meta.pageTitle
    },
    isThemeDark(val) {
      const color = this.navbarColor == "#fff" && val ? "#10163a" : "#fff"
      this.updateNavbarColor(color)
    },
    "$store.state.mainLayoutType"(val) {
      this.setNavMenuVisibility(val)
    }
  },
  computed: {
    bodyOverlay() { return this.$store.state.bodyOverlay },
    contentAreaClass() {
      if(this.mainLayoutType === "vertical") {
        if      (this.verticalNavMenuWidth == "default") return "content-area-reduced"
        else if (this.verticalNavMenuWidth == "reduced") return "content-area-lg"
        else return "content-area-full"
      }
      // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
      else return "content-area-full"
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType == 'hidden',
        'footer-sticky': this.footerType == 'sticky',
        'footer-static': this.footerType == 'static',
      }
    },
    isAppPage() {
      return this.$route.meta.no_scroll
    },
    isThemeDark()     { return this.$store.state.theme == 'dark' },
    layoutTypeClass() { return `main-${this.mainLayoutType}` },
    mainLayoutType()  { return this.$store.state.mainLayoutType  },
    navbarClasses()   {
      return {
        'navbar-hidden'   : this.navbarType == 'hidden',
        'navbar-sticky'   : this.navbarType == 'sticky',
        'navbar-static'   : this.navbarType == 'static',
        'navbar-floating' : this.navbarType == 'floating',
      }
    },
    verticalNavMenuWidth() { return this.$store.state.verticalNavMenuWidth },
    windowWidth()          { return this.$store.state.windowWidth }
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title
    },
    

    updateNavbarColor(val) {
      this.navbarColor = val
      if (val == "#fff") this.isNavbarDark = false
      else this.isNavbarDark = true
    },
    setNavMenuVisibility(layoutType) {
      if((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === "vertical" && this.windowWidth < 1200)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
      }
      else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    },
    activeLinks() {
     // alert('sí funciona')
  var all_links = document.getElementById("sidebarMenu").getElementsByTagName("a"),
     i=0, len=all_links.length,
     full_path = location.href.split('#')[0]; 

  var imprimir = full_path.split('/')[0]+full_path.split('/')[1]+'//'+full_path.split('/')[2]+'/'+full_path.split('/')[3]+'/'+full_path.split('/')[4]
  //alert(imprimir)

  for(; i<len; i++) {
      if(all_links[i].href.split("#")[0] == full_path) {
          all_links[i].className += " activee";
      }
  }
  if("https://cotizador.socasesores.com/app/" == full_path) {
   // all_links[0].className += " active";
  // alert('fucniona')
  }
  //alert ("http://localhost:8080/app/imprimir" + '==' + imprimir) 
 // if("http://localhost:8080/app/imprimir" == imprimir) {

  // all_links[0].className += "impFavclass";
   //alert('fucniona')
  //}
  

    },
    
    GetEjecutivo(){
      let self=this
			var objRequestCount = {
				strApiKey: this.key,
				strMetodo: 'MisDatos'
			}

      this.$axios.post('/',objRequestCount,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
             let Ejecutivo= response.data.objContenido
              this.perfilEjecutivo = Ejecutivo.TipoPerfilEjecutivo            
            
            }else {
            this.$vs.notify({
              title:'Ocurrio un error en Mis datos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})

    },
    async functionSaveEvent(){
      console.log(JSON.parse(localStorage.getItem('userInfo')));
      let  self=this
      var nombreU,BrokerId
      let nombreC=JSON.parse(localStorage.getItem('userInfo')).Nombre
      if(nombreC == null){
        nombreU=''
      }
      else
      {
        nombreU=nombreC
      }
      let BrokerIdC=JSON.parse(localStorage.getItem('userInfo')).IdBrokerSupervisor
      let OrganizacionNombreS=JSON.parse(localStorage.getItem('userInfo')).OrganizacionNombre
      
      if(BrokerIdC == null ){
        BrokerId=0
      }
      else
      {
        BrokerId=BrokerIdC
      }
      await this.SaveNewEvent('InsertarMedicionEventos','Cotización rapida',nombreU,BrokerId,OrganizacionNombreS);
      window.location.replace("https://cotizador.socasesores.com/app/nuevaCotizacion")
      //console.log(localStorage.getItem('userInfo'));
    },
    async SaveNewEvent(metodo,nameOrigen,nombreUs,BrokerId,OrganizacionNombreS)
    {
      
      
			var objRequestCount = {
				strApiKey: this.key,
				strMetodo: metodo,
        objEventos: {
                Origen: nameOrigen,
                nombreUsuario: nombreUs,
                IdBroker: BrokerId,
                NombreOrganizacion: OrganizacionNombreS,
            },
			}

      await this.$axios.post('/',objRequestCount,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            console.log(response)
             
            }else {
            this.$vs.notify({
              title:'Ocurrio un error en guardar eventos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
            console.log(response)
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
    },

    getApikey(){
      this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;      
      this.GetEjecutivo()
    },
  },
  mounted(){
    //alert('entra')
    this.activeLinks()
    this.getApikey()
  
  },
  created() {
    const color = this.navbarColor == "#fff" && this.isThemeDark ? "#10163a" : this.navbarColor
    this.updateNavbarColor(color)
    this.setNavMenuVisibility(this.$store.state.mainLayoutType)
  },
 
}

</script>


<style scoped>
.show {
  display: block;
}

.dropdown-menu.show {
  display: block;
  position: relative !important;
  padding-bottom: 1px !important;
}

.dropdown-menu {
  /*position: absolute;*/
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0px;
  margin: 0;
  font-size: 1rem;
  color: #fff;
  text-align: left;
  list-style: none;
  background-color: transparent !important;
  background-clip: padding-box;
  border: none;
  border-radius: 0.25rem;
  margin-bottom: 20px;
}

.btn-sublink {
  margin-left: 20px;
  margin-right: auto;
  padding: 0px 8px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #fff;
}

.dropdown-menu .sublink {
  margin-top: 0px !important;
}

.dropdown-menu .sublink:last-child {
  margin-bottom: 35px !important;
}

.show {
  display: block;
}

@media print {
  .vx-navbar-wrapper {
    display: none;
  }

  #sidebarMenu,
  .header {
    display: none;
  }

  .wrap-all-the-things {
    padding: 0px;
  }

  @page {

    margin: .5% 1%;
  }
}

body {
  background-color: #F1F4FF !important;
}

.logo-position:hover {
  border: #006D4E 2px solid;
  border-radius: 5px;
}
</style>

