<template>
	<div class="">
		<ul class="nav nav-tabs" id="myTab" role="tablist">
			<li class="nav-item" role="presentation">
				<button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Generales</button>
			</li>
			<li class="nav-item" role="presentation">
				<button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false" @click="update(true)">Ingresos</button>
			</li>
			<!--<li class="nav-item" role="presentation">
				<button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Seguros</button>
			</li>-->
			<li class="nav-item" role="presentation">
				<button class="nav-link" id="adicional-tab" data-bs-toggle="tab" data-bs-target="#adicional" type="button" role="tab" aria-controls="adicional" aria-selected="false">Datos adicionales</button>
			</li>
			<li class="nav-item" role="presentation">
				<button class="nav-link" id="datos-tab" data-bs-toggle="tab" data-bs-target="#productos" type="button" role="tab" aria-controls="productos" aria-selected="false" @click="dameCaso()">Productos</button>
			</li>
		</ul>
		<div class="tab-content" id="myTabContent">
			<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
				<div class="tab-body">
					<div class="accordion" id="accordionExample">
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingOne">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" @click="update(true)">Personales</button>
							</h2>
							<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
								<div class="accordion-body accordion-scrolling mh-4">
									<General v-on:changeCoacreditado="showContentCoacreditado($event)" />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingTwo">
								<button id="headingTwo2" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" @click="update(false)">Reporte de Crédito y Precalificación</button>
							</h2>
							<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
								<div class="accordion-body accordion-scrolling mh-4">
									<ReporteCrediticio :CambioVistaN="this.CambioVista"  />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingThree">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" @click="update(true)">Dependientes</button>
							</h2>
							<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
								<div class="accordion-body accordion-scrolling mh-4">
									<Dependientes />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingFour">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Referencias</button>
							</h2>
							<div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
								<div class="accordion-body accordion-scrolling mh-4">
									<Referencias />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
				<div class="tab-body">
					<div class="accordion" id="accordionExampleING">
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingOneING">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneING" aria-expanded="true" aria-controls="collapseOneING" @click="update(true)">Ingresos</button>
							</h2>
							<div id="collapseOneING" class="accordion-collapse collapse show" aria-labelledby="headingOneING" data-bs-parent="#accordionExampleING">
								<div class="accordion-body accordion-scrolling mh-4">
									<Ingresos />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingTwoING">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoING" aria-expanded="false" aria-controls="collapseTwoING">Cuentas bancarias</button>
							</h2>
							<div id="collapseTwoING" class="accordion-collapse collapse" aria-labelledby="headingTwoING" data-bs-parent="#accordionExampleING">
								<div class="accordion-body accordion-scrolling mh-4">
									<CuentasBancarias />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingThreeING">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreeING" aria-expanded="false" aria-controls="collapseThreeING">Beneficiarios</button>
							</h2>
							<div id="collapseThreeING" class="accordion-collapse collapse" aria-labelledby="headingThreeING" data-bs-parent="#accordionExampleING">
								<div class="accordion-body accordion-scrolling mh-4">
									<Beneficiarios />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
				<div class="tab-body">
					<Aseguradoras />
				</div>
			</div>-->
			<div class="tab-pane fade" id="adicional" role="tabpanel" aria-labelledby="adicional-tab">
				<div class="tab-body">
					<AdicionalesCoacreditado />
				</div>
			</div>
			<div class="tab-pane fade" id="productos" role="tabpanel" aria-labelledby="datos-tab">
				<div class="tab-body">
					<div class="pro-card">
						<div>
							<div class="tab-body-2" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl" v-if="caso.solicitudes.length">
								<div class="col-md-4" v-for="item in caso.solicitudes" :key="item.Id">
									<Solicitados :solicitados="item" :precalificacionTipo = "caso.PrecalificacionTipo">
										<template slot="action-favs">
											<div class="trash-pro">
												<button type="filled" class="favoritos" @click="quitar(item)">
													<svg class="trash">
														<g id="trash">
															<path id="Vector" d="M18,.5C14.67.17,11.32,0,7.98,0A59.068,59.068,0,0,0,2.04.3L0,.5" transform="translate(3 5.48)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
															<path id="Vector-2" data-name="Vector" d="M0,2.97.22,1.66C.38.71.5,0,2.19,0H4.81C6.5,0,6.63.75,6.78,1.67L7,2.97" transform="translate(8.5 2)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
															<path id="Vector-3" data-name="Vector" d="M13.7,0l-.65,10.07c-.11,1.57-.2,2.79-2.99,2.79H3.64C.85,12.86.76,11.64.65,10.07L0,0" transform="translate(5.15 9.14)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
															<path id="Vector-4" data-name="Vector" d="M0,0H3.33" transform="translate(10.33 16.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
															<path id="Vector-5" data-name="Vector" d="M0,0H5" transform="translate(9.5 12.5)" fill="none" stroke="#e42613" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.34" />
															<path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" fill="none" opacity="0" />
														</g>
													</svg>
												</button>
											</div>
										</template>
									</Solicitados>
								</div>
							</div>
							<div v-else>
								<span class="span-placeholder">No hay productos elegidos</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import Solicitados from '@/views/Caso/Solicitados.vue'
import General from '@/views/Caso/Coacreditado/General.vue'
import Ingresos from '@/views/Caso/Coacreditado/Ingresos.vue'
import Beneficiarios from '@/views/Caso/Coacreditado/Beneficiarios.vue'
import Referencias from '@/views/Caso/Coacreditado/Referencias.vue'
import Aseguradoras from '@/views/Caso/Coacreditado/Aseguradoras.vue'
import DocumentosDigitales from '@/views/Caso/Coacreditado/DocumentosDigitales.vue'
import ReporteCrediticio from '@/views/Caso/Coacreditado/ReporteCrediticioPrecalificacion.vue'
import AdicionalesCoacreditado from '@/views/Caso/Coacreditado/AdicionalesCoacreditado.vue'
import CuentasBancarias from '@/views/Caso/Coacreditado/CuentasBancarias.vue'
import Adeudos from '@/views/Caso/Coacreditado/Adeudos.vue'
import Dependientes from '@/views/Caso/Coacreditado/Dependientes.vue'

export default {
  components: {
    General,
    Ingresos,
    Beneficiarios,
    Referencias,
    Aseguradoras,
    DocumentosDigitales,
    ReporteCrediticio,
    AdicionalesCoacreditado,
    CuentasBancarias,
    Adeudos,
	Solicitados,
    Dependientes
  },
  data () {
    return {
      tab:1,
	  id: '',
	  key: '',
	  CambioVista:'',
	  caso: {
				valorInmueble: '',
				saldoInsoluto: '',
				plazos: [],
				destino: '',
				esquemas: [],
				pagoMensualActual: '',
				plazoRestante: '',
				ejecutivo: '',
				estado: '',
				estatus: '',
				favoritos: [],
				solicitudes: [],
				montoSolicitado: '',
				programa: '',
				email: '',
				bancos: [],
				inmuebleNuevo: true,
				estado: '',
				plaza: '',
				solicitante: '',
				tasa: '',
				ssv: '',
				creditoInf: '',
				//gastosNotarial:3,
				valorProyecto: 0,
				valorTerreno: 0,
				montoCredito: 0,
				curp: '', 
				nombre: '',
				appPaterno: '',
				appMaterno: '',
				telefono: '',
				Segnombre: '',
				PrecalificacionTipo: ''
				
			},
		CoAcreditado : ''
    }
  },
  mounted() {
	this.id = this.$route.params.id
	this.getApikey()
	this.dameCaso()
  },
  computed: {
    isSmallerScreen () {
      return window.screen.width < 768
    },
  },
  methods:{
    changeTab(id){
      this.tab=id
    },
		update(val){
			// alert('padre'+val)

			if(val==false){
				this.CambioVista= 'Credito'
			}else{
				this.CambioVista= ' '
				// alert('vista')
				let elemento = document.getElementById('collapseTwo')

					//<div id="collapseTwo" class="accordion-collapse collapse"
					elemento.setAttribute('aria-expanded',"false")
					elemento.classList.remove('show')
					

			}
			


			
		},
		getApikey() {
				if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
					this.$router.push('/login')
				} else {
					this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
				}
		},
		dameCaso(flag) {
				//this.destino=1 //restaurar para que tome los valores devueltos
				this.caso.programa = 1 //restaurar para que tome los valores devueltos
				let self = this
				var objRequestDameCaso = {
					strApiKey: this.key,
					strMetodo: 'DameCaso',
					objCaso: {
						Id: this.id,
					},
				}
				this.$axios
					.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
					.then((response) => {
						if (response.data.intEstatus == 0) {
							let cotizacion = response.data.objContenido
							this.caso.PrecalificacionTipo = cotizacion.PrecalificacionTipo ? cotizacion.PrecalificacionTipo : 1;
				
							if (response.data.objContenido.CoPropietario != null) {
								this.CoAcreditado = true	
							}
							else
							this.CoAcreditado = false
							
							
							this.caso.solicitudes = cotizacion.Solicitudes
							for (var i = 0; i < this.caso.solicitudes.length; i++) {
								this.caso.solicitudes[i].Resultado = this.caso.solicitudes[i].Resultado
								this.caso.solicitudes[i].IdCaso = this.caso.solicitudes[i].Caso
								this.caso.solicitudes[i].IdBanco = this.caso.solicitudes[i].IdBanco
								this.caso.solicitudes[i].MensualidadPromedio = this.caso.solicitudes[i].MensualidadPromedio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								this.caso.solicitudes[i].Cat = this.caso.solicitudes[i].Cat.toFixed(2)
								this.caso.solicitudes[i].CoAcreditado = this.CoAcreditado
									.toString()
									.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
							}
							
							
							this.$vs.loading.close('#datosCotizacion > .con-vs-loading')
						} else {
							this.$vs.notify({
								title: 'Ocurrio un error en DameCaso',
								text: response.data.strError,
								color: 'danger',
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						self.$vs.notify({
							title: 'Ocurrio un error de sistema',
							text: error,
							color: 'danger',
							position: 'top-right',
						})
					})
		},
		quitar(item) {
			let self = this
			var objRequestSolicitaCaso = {
				strApiKey: this.key,
				strMetodo: 'BorrarSolicita',
				intVariante: item.VarianteId,
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestSolicitaCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						this.$vs.notify({
							title: 'Eliminado exitosamente',
							position: 'top-right',
						})
						if (item.IdBanco == 10) {
							this.$store.commit('UPDATE_EXISTE_PRODUCTO_SANTANSER', false)
							this.$router.go(0)
						}
						this.dameCaso()
					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en BorrarSolicita',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},

		showContentCoacreditado(changeCoacreditado) {

      this.$emit('changeCoacreditado', changeCoacreditado)

    },
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
